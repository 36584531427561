.fixedTopbar {
  .topbar {
    position: fixed;
    left: $sidebarWidth;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
  }
  .HorizontalTopbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    background: rgba(255, 255, 255, 0.4); /* Semi-transparent overlay */
    backdrop-filter: blur(10px); /* Blurs the background */
  }
  &.contentArea {
    padding-top: $topbarHeight;
  }
}
.ddWidth {
  width: 300px;
}

.mega-dropdown {
  position: static;
  .dropdown-menu {
    padding: 30px;
    width: 96%;
    left: -2% !important;
    max-height: 480px;
    top: 11px !important;
    overflow: auto;
  }
}

/* Default Dropdown Menu for Large Screens */
.dropdown-menu-custom {
  position: absolute; /* Normal alignment for large screens */
  z-index: 1050; /* Ensures visibility above other elements */
  min-width: 300px;
  max-width: 350px; /* Fixed width for normal dropdown behavior */
  max-height: 70vh; /* Prevents it from being too tall */
  overflow-y: auto; /* Adds scroll for long content */
  background-color: white; /* Clean background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  border-radius: 8px; /* Rounded corners */
  top: 130%; /* Positions below the toggle */
  left: auto; /* Aligns to the parent element's default alignment */
  right: 0; /* Ensures alignment to the right of the trigger */
  opacity: 0; /* Initially hidden */
  transform: translateY(10px); /* Slight offset for animation */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transitions */
}

/* Show State */
.dropdown-menu-custom.show {
  opacity: 1; /* Visible when active */
  transform: translateY(0); /* Removes offset for a smooth drop */
}

/* Responsive Adjustments for Small Screens */
@media screen and (max-width: 768px) {
  .dropdown-menu-custom {
    position: fixed; /* Positions relative to the viewport */
    left: 5%; /* Padding from the left edge */
    right: 5%; /* Padding from the right edge */
    max-width: 90%; /* Stretches to fit within screen width */
    top: 65px; /* Default behavior for downward placement */
    transform: translateY(0); /* Ensures proper alignment */
  }

  .dropdown-menu-custom.show {
    top: 65px; /* Adjust to open upward if space below is insufficient */
   
  }
}

@include media-breakpoint-up(xs) {
.AppBody{
  margin-top: calc(20px + 1rem);
}
}

@include media-breakpoint-up(sm) {
.AppBody{
  margin-top: calc(20px + 1rem);
}
}

@include media-breakpoint-up(md) {
.AppBody{
  margin-top: calc(20px + 1rem);
}
}

@include media-breakpoint-up(lg) {
.AppBody{
  margin-top: calc(80px + 1rem);
}
}

@include media-breakpoint-up(lg) {
  .fixedTopbar {
    .topbar {
      width: 100%;
      left: 0;
    }
  }
  // .isMiniSidebar .fixedTopbar .topbar {
  //   width: 100%;
  //   left: 0;
  // }
  .logo-space {
    min-width: 245px;
    padding-left: 10px;
  }
  .isMiniSidebar .logo-space {
    min-width: 60px;
  }
}
@include media-breakpoint-down(lg) {
  .ddWidth {
    width: 100vw;
    max-width: 400px;
  }
}
