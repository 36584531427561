.customizerSidebar {
  width: $sidebarWidth;
  background-color: $white;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 6;
  transition: 0.2s ease-in;
  margin-right: -$sidebarWidth;
  &.showCustomizer {
    margin-right: 0;
  }
}
.custombtn {
  margin-left: -58px;
  position: fixed;
  top: 50%;
  border-radius: 5px 0 0 5px;
}

.table thead tr th{
  background-color: rgba(0,0,0,0.005) !important;
}

.table thead tr{
  background-color: rgba(0,0,0,0.05) !important;
  border-bottom: 1px solid rgba(0,0,0,0.05) !important;
  vertical-align: middle;
}

.table thead tr th{
 border-left: 1px solid  rgba(0,0,0,0.1);
 font-size: 11px;
 font-weight: bold;
}

.table thead tr th:first-child{
  border-left: 1px solid  rgba(0,0,0,0);
}

.form-floating>label{
  z-index: 0 !important;
}

.table tbody tr td{
 font-size: 14px;
}

.table.SmallFont tbody tr td{
 font-size: 12px;
}


.nav-fill .nav-item, .nav-fill>.nav-link{
  padding: 10px;
}

.nav-pills .nav-link{
  background-color: #fcfcfc;
  border: 1px solid #f0f0f0;
}
