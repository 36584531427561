$enable-negative-margins: true;

$breadcrumb-divider: '>';

$theme-colors: (
  "primary": #000080,
  "secondary": #474747,
  "success": #198754,
  "info": #0dcaf0,
  "warning": #edc603,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": #212529,
);

@import '~bootstrap/scss/bootstrap';
