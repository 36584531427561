body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input.form-control:required ~ label.form-label:after,
select.form-select:required ~ label.form-label:after,
textarea.form-control:required ~ label.form-label:before {
  content: "*";
  color: red;
  position: initial;
}

.cursor-pointer {
  cursor: pointer;
}

.lh-0 {
  line-height: 0;
}
