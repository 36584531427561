.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  margin-top: $topbarHeight;
  ul.nav {
    > .nav-item {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .sidebarBox {
    height: 100%;
    width: $sidebarWidth;
    position: relative;
    transition: 0.1s ease-in;
    z-index: 2;
  }
  .nav-item.activeParent > .nav-link {
    background: $white;
    color: $dark;
    border-radius: 4px;
    opacity: 1;
  }
  .fixedSidebar {
    position: fixed;
  }
  .navCaption {
    font-size: 0.775rem;
    padding: 5px 15px;
    width: 100%;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nav-item.activeParent > .nav-link {
    opacity: 1;
  }
  .nav-link {
    color: inherit;
    opacity: 1;
    display: flex;
    padding: 12px;
    align-items: center;
    white-space: nowrap;
    svg {
      /*width: 21px;*/
      font-size: 24px;
    }
    &:hover {
      opacity: 1;
    }
  }
  .lightText {
    color: $primary;
  }
  .activeLink .nav-link {
    opacity: 1;
    font-weight: bold;
  }
  .subMenu {
    padding: 0;
    list-style: none;
    transition: 0.1s ease-in;
    .nav-link {
      padding: 9px 15px;
    }
    .sidebarIcon {
      visibility: hidden;
    }
  }
}
.sidebarOverlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}
.horizontalNav {
  position: relative;
  z-index: 1;

  .nav-item {
    position: relative;
  }
  .nav-link {
    color: inherit;
    opacity: 1;
    display: flex;
    padding: 15px 12px;
    transition: 0.1s ease-in;
    svg {
      /*width: 21px;*/
      font-size: 24px;
    }
    &:hover {
      opacity: 1;
    }
  }
  .firstDD {
    .nav-link {
      padding: 9px 15px;
      &:hover {
        padding-left: 20px;
      }
    }
    svg {
      width: 24px;
    }
  }
  .lightText {
    color: $primary;
  }
  .activeLink .nav-link {
    opacity: 1;
    font-weight: bold;
  }
}
@include media-breakpoint-up(lg) {
  .horizontalNav {
    &.fixedSidebar {
      position: fixed;
      width: 100%;
    }

    .firstDD {
      position: absolute;
      min-width: 250px;
      padding: 10px;
      display: none;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
    }
    .nav-item:hover .firstDD {
      display: block;
    }
  }
  .HsidebarFixed {
    padding-top: 64px;
  }
}

@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    z-index: 2;
    margin-left: -$sidebarWidth;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
  .horizontalNav {
    width: $sidebarWidth;
    margin-left: -$sidebarWidth;
    position: fixed;
    height: 100%;
    overflow-y: auto;
    transition: 0.1s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
    .firstDD {
      display: none;
      &.showfirstDD {
        display: block;
      }
    }
    .nav-link,
    .nav-item {
      width: 100%;
    }
    .nav-item:focus .firstDD {
      display: block;
    }
  }
}
@include media-breakpoint-up(lg) {
  .isMiniSidebar {
    .sidebarArea {
      width: $miniSidebarWidth;
      .sidebarBox {
        width: $miniSidebarWidth;
        &:hover {
          width: $sidebarWidth;
          .hide-mini {
            display: block;
          }
        }
        .hide-mini {
          display: none;
        }
      }
    }
  }
}

// skin white
.sidebarArea {
  .sidebarBox {
    &.bg-white {
      .nav-item.activeParent > .nav-link,
      .nav > .activeLink .nav-link {
        background: $success;
        color: $white;
        border-radius: $border-radius;
      }
    }
  }
}

.ReactModal__Content {
  &.modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;

    .modal-content {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, .2);
      border-radius: 0.3rem;

      .modal-body {
        padding: 1rem;
      }

      .modal-header {
        padding: 1rem;
        border-bottom: 1px solid #dee2e6;
      }

      .modal-footer {
        padding: 0.75rem;
        border-top: 1px solid #dee2e6;

        * {
          margin: 0.25rem
        }
      }
    }
  }
}